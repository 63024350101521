<template>
  <div class="term">
    <h1>Terms of Service</h1>
    <div class="scrollableview">
      <p>
        These Terms of Service are a legally binding agreement made between you
        (the "You" or "User") and eHealthInsurance Services, Inc. ("eHealth" or
        "We") concerning your access to, and use of, reports provided by the
        Partner Reporting Tool located at https://ehealth.looker.com/login
        and/or made accessible to you by eHealth other means (collectively, the
        "Tool"). User agrees by accessing the Tool, User has read, understood,
        and agrees to be bound by all these Terms of Service. If User does not
        agree with all of these Terms of Service, then User is expressly
        prohibited from using the Tool and User must discontinue use
        immediately. Supplemental Terms of Service or other documents that may
        be posted on the Tool from time to time are expressly incorporated
        herein by reference. We reserve the right, in our sole discretion, to
        make changes or modifications to these Terms of Service at any time and
        for any reason. We will alert User about any changes by updating the
        "Last Updated" date of these Terms of Service, and User waives any right
        to receive specific notice of each such change. It is User's
        responsibility to periodically review these Terms of Service to stay
        informed of updates. User will be subject to, and will be deemed to have
        been made aware of and to have accepted, the changes in any revised
        Terms of Service by User's continued use of the Tool after the date such
        revised Terms of Service are posted.
      </p>
      <br />

      <p>
        The Tool is intended for Users who are at least 18 years old. Persons
        under the age of 18 are not permitted to register for the Tool.
      </p>
      <br />

      <p>
        By using the Tool, User represents and warrants that: User will not
        access the Tool through automated or non-human means, whether through a
        bot, script, or otherwise; User will not use the Tool for any illegal or
        unauthorized purpose; User's use of the Tool will not violate any
        applicable law or regulation; User is accessing this report/these
        reports on behalf of an eHealth Partner pursuant to an executed Referral
        Agreement and the information contained therein shall at all times be
        treated as Confidential Information pursuant to the Referral Agreement;
        to the extent such report includes Non-Public Personal Information
        ("NPI"), User agrees that it is accessing this report/these reports for
        the purpose of performing services on behalf of eHealth, including
        marketing and promotional activities, and that such NPI shall not be
        used or further disclosed for any other purpose; to the extent such
        report includes Protected Health Information ("PHI"), User agrees that
        it is accessing this report/these reports for the purposes of providing
        services on behalf of eHealth as set forth in executed business
        associate or sub-business associate agreement, and that such PHI shall
        not be further used or disclosed for any other purpose. User represents
        and warrants that there are implemented and maintained reasonable
        safeguards to protect all reports and the contents therein, including
        but not limited to NPI and PHI, from unauthorized disclosure; and that
        the Tool and the contents therein, including but not limited to NPI and
        PHI, shall not be accessed from outside of the United States. User
        further represents and warrants that it will not upload or transmit (or
        attempt to upload or transmit) viruses, Trojan horses, or other material
        that interferes with any party's uninterrupted use and enjoyment of the
        Tool or modifies, impairs, disrupts, alters, or interferes with the use,
        features, functions, operation, or maintenance of the Tool. User shall
        not use the Tool in a manner inconsistent with any applicable law or
        regulation.
      </p>
      <br />

      <p>
        User is required to register to gain access to the Tool. User agrees to
        keep its password confidential and will be responsible for all use of
        its account and password. It is expressly prohibited to share your
        account and password.
      </p>
      <br />

      <p>
        We reserve the right to terminate or deny access to the Tool in our sole
        discretion, for any reason.
      </p>
      <br />

      <p>
        User may not access or use the Tool for any purpose other than that for
        which we make the Tool available.
      </p>
      <br />

      <p>Term and Termination</p>
      <br />

      <p>
        These Terms of Service shall remain in full force and effect while User
        uses the Tool. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
        SERVICE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
        NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE TOOL (INCLUDING
        BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
        REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
        WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF SERVICE OR OF ANY
        APPLICABLE LAW OR REGULATION. WE MAY TERMINATE USER’S USE OR
        PARTICIPATION IN THE TOOL OR DELETE YOUR ACCOUNT, WITHOUT WARNING, IN
        OUR SOLE DISCRETION.
      </p>
      <br />

      <p>Governing Law</p>
      <br />

      <p>
        These Terms of Service and User's use of the Tool are governed by and
        construed in accordance with the laws of the State of California
        applicable to agreements made and to be entirely performed within the
        State of California without regard to its conflict of law principles.
      </p>
      <br />

      <p>Disclaimers</p>
      <br />

      <p>
        THE TOOL IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. USER AGREES
        THAT ITS USE OF THE TOOL WILL BE AT ITS SOLE RISK. TO THE FULLEST EXTENT
        PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
        CONNECTION WITH THE TOOL AND USER’S USE THEREOF, INCLUDING, WITHOUT
        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF REPORT(S) OR THE
        CONTENT OF ANY REPORT(S) AND WE WILL ASSUME NO LIABILITY OR
        RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF REPORTS
        OR THE INFORMATION CONTAINED THEREIN, (2) ANY UNAUTHORIZED ACCESS TO OR
        USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED
        THEREIN, (3) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
        THE TOOL, (4) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
        TRANSMITTED TO OR THROUGH THE TOOL BY ANY THIRD PARTY, AND/OR (5) ANY
        ERRORS OR OMISSIONS IN ANY REPORT OR FOR ANY LOSS OR DAMAGE OF ANY KIND
        INCURRED AS A RESULT OF THE USE OF ANY REPORT POSTED, TRANSMITTED, OR
        OTHERWISE MADE AVAILABLE VIA THE TOOL.
      </p>
      <br />

      <p>Limitation of Liability</p>
      <br />

      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        USER OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM USER’S
        USE OF THE TOOL, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES.
      </p>
      <br />

      <p>Indemnification</p>
      <br />

      <p>
        User agrees to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys' fees and expenses,
        made by any third party due to or arising out of: (1) User's use of the
        Tool; (2) User's breach of these Terms of Service; and (3) any breach of
        User's representations and warranties set forth in these Terms of
        Service. Notwithstanding the foregoing, eHealth reserve the right, at
        User's expense, to assume the exclusive defense and control of any
        matter for which you are required to indemnify us, and User agrees to
        cooperate, at User's expense, with our defense of such claims. We will
        use reasonable efforts to notify User of any such claim, action, or
        proceeding which is subject to this indemnification upon becoming aware
        of it.
      </p>
      <br />
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="form-group form-check">
        <input
          type="checkbox"
          v-model="user.accept"
          id="accept"
          class="form-check-input"
        />
        <label class="form-check-label" for="accept">Accept</label>
      </div>
      <br />
      <div class="form-group">
        <input type="hidden" v-model="state" name="state" />
        <input type="hidden" v-model="token" name="token" />
      </div>

      <div class="form-group">
        <button class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { PCS_SERVICE_URL, LOOKER_TERMS_ENDPOINT } from "@/constants";

export default {
  name: "LookerTerms",
  components: {},
  props: ["state", "token"],
  data() {
    return {
      user: {
        accept: false,
      },
    };
  },
  methods: {
    handleSubmit() {
      if (this.user.accept) {
        var data = {
          state: this.state,
          token: this.token,
          accept: true,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };

        fetch(PCS_SERVICE_URL + LOOKER_TERMS_ENDPOINT, requestOptions)
          .then((response) => response.text())
          .then((data) => {
            if (data && data.length > 0) {
              window.location.href = data;
            }
          });
      }
    },
  },
};
</script>

<style>
.scrollableview {
  height: 600px;
  width: 800px;
  overflow: auto;
  margin-bottom: 20px;
}
</style>
