let envConf = {
    env: process.env.VUE_APP_ENV,
    pcsServiceUrl: process.env.VUE_APP_PCS_SERVICE_URL,
};
const w = window;

if (typeof w.envConfig === 'object') {
    envConf = { ...envConf, ...w.envConfig };
}
export const conf = envConf;
