import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import LookerTerms from "../views/LookerTerms.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/looker_terms",
    name: "LookerTerms",
    component: LookerTerms,
    props: (route) => ({ state: route.query.state, token: route.query.token }),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
